<template>
  <div class="vg_wrapper">
    <el-card>
      <publicTips></publicTips>
      <div class="vd_search_group">
        <el-form :inline="true" label-width="120px" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getMdtfsNow()">
          <el-row>
            <el-col :md="8">
              <el-form-item label="材料编号:">
                <el-input size="small" v-model.trim="searchForm.mdrl_no" clearable placeholder="请填写材料编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="材料名称:">
                <el-input size="small" v-model.trim="searchForm.mdrl_name" clearable placeholder="请填写材料名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="出入库类型:">
                <el-select size="small" v-model="searchForm.mdtf_type" placeholder="请选择出入库类型" clearable>
                  <el-option v-for="item in mdtfType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="材料库位:">
                <el-select size="small" filterable v-model="searchForm.mqcd_bin" placeholder="请选择库位" clearable>
                  <el-option v-for="item in binList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="材料类型:">
                <el-select size="small" v-model="searchForm.mdrl_type" placeholder="请选择材料类型" clearable>
                  <el-option v-for="item in mdrlType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="16">
              <el-form-item label="时间范围:">
                <div class="block vg_mr_32">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getMdtfsNow()" class="vg_ml_16">查询</el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow(0)" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增入库</el-button>
        <el-button type="warning" size="small" @click="addRow(1)" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增出库</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" border v-loading="loadFlag">
            <el-table-column label="材料编号" prop="mdrl_no" />
            <el-table-column label="材料图片" align="center">
              <template v-slot="scope">
                <el-popover v-if="scope.row.imge_url" placement="right" width="400" trigger="hover">
                  <div>
                    <el-image style="width: 100%" class="vd_popimg" :src="formatPic(scope, 'l')" fit="fill" />
                  </div>
                  <el-image slot="reference" class="vd_elimg" :src="formatPic(scope, 's')" fit="fill"> </el-image>
                </el-popover>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="材料名称" prop="mdrl_name" show-overflow-tooltip />
            <el-table-column label="材料规格" prop="mdrl_spec" show-overflow-tooltip />
            <el-table-column label="材料类型" prop="mdrl_type" :formatter="formatLeavType" />
            <el-table-column label="材料数量" prop="mdtf_num" />
            <el-table-column label="出入库类型" prop="mdtf_type">
              <template v-slot="scope">
                <span v-if="scope.row.mdtf_type === 0 || scope.row.mdtf_type === 1">
                  {{ scope.row.mdtf_type | formatMdtfType }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="库位" prop="mqcd_bin">
              <template v-slot="scope">
                <span v-if="scope.row.mqcd_bin">
                  {{ scope.row.mqcd_bin }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="备注" prop="mdtf_remark" show-overflow-tooltip>
              <template v-slot="scope">
                <span v-if="scope.row.mdtf_remark">
                  {{ scope.row.mdtf_remark }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina" />
            <el-table-column label="创建时间" prop="create_time" :formatter="formatDate" />
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mdtfAPI } from '@api/modules/mdtf';
import { optnAPI } from '@api/modules/optn';
import pubPagination from '@/components/common/pubPagination';
import selectSectionType from '@/views/component/selectSectionType';
import publicTips from '@/views/component/publicTips';
import helper from '@assets/js/helper.js';

export default {
  name: 'MdtfList',
  components: {
    pubPagination,
    publicTips,
    selectSectionType
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        mdtf_type: null,
        mdrl_name: null,
        mdrl_no: null,
        qrcd_bin: null,
        mdrl_color: null,
        mdrl_type: null,
        colr_class: [],
        timeValue: [],
        belo_dept_id: null //所属部门
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      currentPage: 1,
      binList: [],
      mdtfType: [
        { id: 0, label: '入库' },
        { id: 1, label: '出库' }
      ],
      mdrlType: [
        { id: 1, label: '主材料' },
        { id: 2, label: '辅材料' },
        { id: 3, label: '其他' }
      ],
      optnColorList: [],
      isDyj: true,
      beloDeptId: null
    };
  },
  created() {
    this.initData();
  },
  filters: {
    // 出入库类型
    formatMdtfType(row) {
      if (row === 0) {
        return '入库';
      } else if (row === 1) {
        return '出库';
      }
    },
    formaUnitH(row) {
      return helper.reservedDigits(row) + '毫米';
    }
  },
  watch: {
    $route(to, from) {
      if (from.path === '/mdtf_add' || from.path === '/mdtf_addO') {
        this.getMdtfsList();
        this.getBinList();
      }
    }
  },
  methods: {
    initData() {
      this.getMdtfsList();
      this.getBinList();
    },
    // 获取库位信息
    getBinList() {
      get(optnAPI.getAllContent, { perm_id: 10006 }).then(res => {
        if (res.data.code === 0) {
          this.binList = res.data.data;
        }
      });
    },
    // 获取出入库信息
    getMdtfsList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      this.searchForm.start_time = timeNewVal.startTime;
      this.searchForm.end_time = timeNewVal.endTime;
      this.searchForm.page_no = this.currentPage;
      get(mdtfAPI.getMdtfs, this.searchForm).then(res => {
        if (res.data.code === 0) {
          this.loading = false;
          this.tableData = res.data.data.list;
          this.totalPage = res.data.data.total;
          this.btn = res.data.data.btn;
          setTimeout(() => {
            this.loadFlag = false;
          }, 500);
        }
      });
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        page_no: 1
      };
      this.loadFlag = true;
      this.currentPage = 1;
      this.getMdtfsList();
      this.getBinList();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getMdtfsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getMdtfsList();
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    // //新增
    addRow(val) {
      const permId = this.$route.query.perm_id;
      if (val === 0) {
        this.jump(`/mdtf_add?perm_id=${permId}`);
      } else if (val === 1) {
        this.jump(`/mdtf_addO?perm_id=${permId}`);
      }
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getMdtfsList();
    },
    // 图片类型
    formatPic(scope, type) {
      return this.helper.picUrl(scope.row.imge_url, type, scope.row.create_time);
    },
    // 材料类型
    formatLeavType(row) {
      if (row.mdrl_type === 1) {
        return '主材料';
      } else if (row.mdrl_type === 2) {
        return '辅材料';
      } else {
        return '其他';
      }
    },
    formatMtrlNum(row) {
      return this.helper.haveFour(row.mdrl_num) + ' ' + row.mdrl_unit;
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    //查询范围
    getStatusVal2(val) {
      this.searchForm.belo_dept_id = val;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
  margin-top: 5px;
}
.vd_dis {
  display: flex;
}
.vd_div {
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}
</style>
